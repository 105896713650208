






































import { Component } from 'vue-property-decorator'
import InputBox from '../../common/input-box/inputBox.vue'
import API from '@/api/index.js'
import myMixins from '@/mixins/common.ts'

@Component({
    components: {
        InputBox
    }
})
export default class ChangePassword extends myMixins {
    password = {
        old: '',
        newFirst: '',
        newSecond: ''
    }
    inputTypeA = 'password'
    inputTypeB = 'password'
    inputTypeC = 'password'
    canClick = false

    valueChange(params: Param) {
        const { name, val } = params
        this.password[name] = val
        const { old, newFirst, newSecond } = this.password
        if (old !== '' && newFirst !== '' && newFirst.length >= 8 && JSON.stringify(newFirst) === JSON.stringify(newSecond)) {
            this.canClick = true
        } else {
            this.canClick = false
        }
    }

    inputTypeChange(params: Param) {
        const { name, val } = params
        this[name] = val
    }

    async changePassword() {
        if (this.canClick) {
            const { old, newFirst } = this.password
            const res = await API.resetPasswordByOld({
                oldPassword: old,
                password: newFirst
            })
            if (res && res.data && res.data.errorCode === 200) {
                this.routerChange('personalInfo')
            }
        }
    }
}
